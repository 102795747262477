import { Button, Col, Form, Nav, Row } from "react-bootstrap";
import { InitialHeaderStyles } from "../../styles";
import { ItemLogsNavBar } from "../../../../../styles/initialStyles";
import { useContext, useState } from "react";
import { BsCopy, BsFillCameraVideoFill, BsFillCreditCard2BackFill, BsPencilSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom"; // Importar o hook useNavigate
import ModalInfoMeeting from "../ModalInfoMeeting/ModalInfoMeeting";
import ModalMoreDetailsMeeting from "../ModalMoreDetailsMeeting/ModalMoreDetailsMeeting";
import ModalMeetingParticipants from "../ModalMeetingParticipants/ModalMeetingParticipants";
import ModalErrorsMeeting from "../ModalErrorsMeeting/ModalErrosMeeting";

const ModalMeetingDetails = () => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate(); 

  const changeActiveTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <InitialHeaderStyles>
        <section className="headingStickySubPages">
          <Row className="headerWithButton">
            <section>
              <h2>Detalhes da agenda</h2>
            </section>
          </Row>
        </section>
      </InitialHeaderStyles>
      <Form.Group as={Row} className="meetingFormGroupRow mt-2">
        <Col sm={12} className="meetingFormGroupCol">
          <div className="d-flex justify-content-between mt-2">
            <Button variant="outline-secondary" className="back-button" onClick={handleGoBack}>
              Voltar
            </Button>
            <div>
              <Button variant="outline-primary" className="continue-button me-2">
                Ressincronizar
              </Button>
              <Button variant="primary" className="continue-button ms-2">
                Editar Informações
                <BsPencilSquare
                  color="#fff"
                  size="24"
                  style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#fff", strokeWidth: 1.0 }}
                />
              </Button>
            </div>
          </div>
        </Col>
      </Form.Group>

      <Row className="nav-item px-0">
        <ItemLogsNavBar>
          <Nav
            variant="pills"
            defaultActiveKey="/home"
            className="px-0 mt-1 mb-0 pl-0 ml-0"
          >
            <Nav.Item className="mx-0">
              <Nav.Link
                active={activeTab === 1}
                onClick={() => changeActiveTab(1)}
                className="pt-3 pb-2"
              >
                Informações da agenda
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-1">
              <Nav.Link
                active={activeTab === 2}
                onClick={() => changeActiveTab(2)}
                className="pt-3 pb-2"
              >
                Participantes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-1">
              <Nav.Link
                active={activeTab === 3}
                onClick={() => changeActiveTab(3)}
                className="pt-3 pb-2"
              >
                Mais detalhes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-1">
              <Nav.Link
                active={activeTab === 4}
                onClick={() => changeActiveTab(4)}
                className="pt-3 pb-2"
              >
                Erros
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </ItemLogsNavBar>
      </Row>

      <Row className="">
        <Col md={12}>
          {activeTab === 1 && <ModalInfoMeeting />}
          {activeTab === 2 && <ModalMeetingParticipants />}
          {activeTab === 3 && <ModalMoreDetailsMeeting />}
          {activeTab === 4 && <ModalErrorsMeeting />}
        </Col>
      </Row>
    </>
  );
};

export default ModalMeetingDetails;
